var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    { attrs: { width: "550", height: "650", zIndex: "52000" } },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(_vm._s(_vm.headingTitle)),
      ]),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", {}, [_vm._v("Room Name (must match name in API)")]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:100",
                      expression: "'required|max:100'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Name" },
                  model: {
                    value: _vm.roomName,
                    callback: function ($$v) {
                      _vm.roomName = $$v
                    },
                    expression: "roomName",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Name"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full mt-3" },
              [
                _c("label", {}, [_vm._v("Room Rename")]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:100",
                      expression: "'max:100'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Rename" },
                  model: {
                    value: _vm.roomRename,
                    callback: function ($$v) {
                      _vm.roomRename = $$v
                    },
                    expression: "roomRename",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Rename"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-1/3 mt-3" },
              [
                _c("label", {}, [_vm._v("Sleeps Total")]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "greaterThanOrEqualToZero",
                      expression: "'greaterThanOrEqualToZero'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { type: "number", name: "Sleeps Total" },
                  model: {
                    value: _vm.sleepsTotal,
                    callback: function ($$v) {
                      _vm.sleepsTotal = _vm._n($$v)
                    },
                    expression: "sleepsTotal",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Sleeps Total"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-1/3 mt-3" },
              [
                _c("label", {}, [_vm._v("Sleeps Adult")]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "greaterThanOrEqualToZero",
                      expression: "'greaterThanOrEqualToZero'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { type: "number", name: "Sleeps Adult" },
                  model: {
                    value: _vm.sleepsAdult,
                    callback: function ($$v) {
                      _vm.sleepsAdult = _vm._n($$v)
                    },
                    expression: "sleepsAdult",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Sleeps Adult"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-1/3 mt-3" },
              [
                _c("label", {}, [_vm._v("Sleeps Children")]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "greaterThanOrEqualToZero",
                      expression: "'greaterThanOrEqualToZero'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { type: "number", name: "Sleeps Children" },
                  model: {
                    value: _vm.sleepsChildren,
                    callback: function ($$v) {
                      _vm.sleepsChildren = _vm._n($$v)
                    },
                    expression: "sleepsChildren",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Sleeps Children"))),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-start mt-6" },
            [
              _c("h5", { staticClass: "mt-1 font-medium" }, [
                _vm._v("Room Description"),
              ]),
              _c(
                "vs-button",
                {
                  staticClass: "ml-4",
                  attrs: { color: "primary", type: "border", size: "small" },
                  on: { click: _vm.onAddContent },
                },
                [_vm._v("Add")]
              ),
            ],
            1
          ),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            style: { height: "180px" },
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.roomContents,
              gridOptions: _vm.gridOptions,
              rowSelection: "single",
              pagination: false,
              suppressPaginationPanel: true,
              context: _vm.context,
            },
          }),
          _c("div", { staticClass: "flex items-start mt-2 mb-4" }, [
            _c("h5", { staticClass: "mt-1 font-medium" }, [
              _vm._v("Room Images (upload via the main property page)"),
            ]),
          ]),
          _c("room-image-list", {
            attrs: { imageList: _vm.roomImages },
            on: { onAssignImageToRoom: _vm.handleAssignImageToRoom },
          }),
          _vm.showContentModal
            ? _c("room-content-modal", {
                attrs: {
                  data: _vm.roomContentInEdit,
                  formData: _vm.formData,
                  isNew: _vm.isModalAdding,
                },
                on: {
                  onAdd: _vm.handleContentAdded,
                  onEdit: _vm.handleContentUpdated,
                  onCancel: _vm.handleContentModalCancel,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-end",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "danger", type: "filled" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.save },
            },
            [_vm._v(_vm._s(_vm.saveButtonText))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }