var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex items-start mt-4 mt-base mb-4" },
        [
          _c("h5", { staticClass: "mt-1 font-medium" }, [_vm._v("Rooms")]),
          _c(
            "vs-button",
            {
              staticClass: "ml-4",
              attrs: { color: "primary", type: "border", size: "small" },
              on: { click: _vm.onAddRoom },
            },
            [_vm._v("Add")]
          ),
        ],
        1
      ),
      _c("ag-grid-vue", {
        ref: "agGridTable",
        staticClass: "ag-theme-material mb-3",
        attrs: {
          components: _vm.cellRendererComponents,
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          rowData: _vm.roomList,
          gridOptions: _vm.gridOptions,
          domLayout: "autoHeight",
          rowSelection: "single",
          pagination: false,
          suppressPaginationPanel: true,
          context: _vm.context,
        },
        on: {
          "first-data-rendered": _vm.onFirstDataRendered,
          "grid-ready": _vm.onGridReady,
        },
      }),
      _vm.showModal
        ? _c("room-edit-modal", {
            attrs: {
              roomData: _vm.roomInEdit,
              formData: _vm.formData,
              isNew: _vm.isModalAdding,
            },
            on: {
              onEdit: _vm.handleEdit,
              onAdd: _vm.handleAdd,
              onCancel: function ($event) {
                _vm.showModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }