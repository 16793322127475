var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("feather-icon", {
        attrs: {
          title: "Delete",
          icon: "TrashIcon",
          svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
        },
        on: {
          click: function ($event) {
            _vm.showDeletePopup = true
          },
        },
      }),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Delete Image",
            "accept-text": "Delete",
            active: _vm.showDeletePopup,
          },
          on: {
            accept: _vm.onDeleteImage,
            "update:active": function ($event) {
              _vm.showDeletePopup = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row mt-2 mb-2" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("div", {}, [
                _vm._v("Are you sure you want to delete this image?"),
              ]),
              _c(
                "div",
                { staticClass: "custom-property-image-container mt-3" },
                [
                  _c("img", {
                    staticClass: "custom-property-image",
                    attrs: { src: _vm.params.data.previewUrl },
                  }),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }