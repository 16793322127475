var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v(_vm._s(_vm.formTitle)),
      ]),
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Engine Reference*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:20",
                      expression: "'required|max:20'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Engine Reference" },
                  model: {
                    value: _vm.form.reference,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "reference", $$v)
                    },
                    expression: "form.reference",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Engine Reference"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Supplier")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    reduce: (option) => option.code,
                    name: "Supplier",
                    options: _vm.formData.supplierOptions,
                    clearable: false,
                  },
                  model: {
                    value: _vm.form.supplierId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "supplierId", $$v)
                    },
                    expression: "form.supplierId",
                  },
                }),
              ],
              1
            ),
            _c("span", { staticClass: "text-danger text-sm" }, [
              _vm._v(_vm._s(_vm.errors.first("Supplier"))),
            ]),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Name*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:100",
                      expression: "'required|max:100'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Name" },
                  model: {
                    value: _vm.form.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Name"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Property Type")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.code,
                    name: "Property Type",
                    options: _vm.formData.propertyTypeOptions,
                    clearable: false,
                  },
                  model: {
                    value: _vm.form.propertyTypeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "propertyTypeId", $$v)
                    },
                    expression: "form.propertyTypeId",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Rating")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.code,
                    name: "Rating",
                    options: _vm.ratingOptions,
                    clearable: false,
                  },
                  model: {
                    value: _vm.form.rating,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "rating", $$v)
                    },
                    expression: "form.rating",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Address")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:100",
                      expression: "'max:100'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Address" },
                  model: {
                    value: _vm.form.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "address", $$v)
                    },
                    expression: "form.address",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Address"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("City*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:25",
                      expression: "'required|max:25'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "City" },
                  model: {
                    value: _vm.form.city,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "city", $$v)
                    },
                    expression: "form.city",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("City"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("State")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:25",
                      expression: "'max:25'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "State" },
                  model: {
                    value: _vm.form.state,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "state", $$v)
                    },
                    expression: "form.state",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("State"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Postal Code")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:15",
                      expression: "'max:15'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Postal Code" },
                  model: {
                    value: _vm.form.postalCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "postalCode", $$v)
                    },
                    expression: "form.postalCode",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Postal Code"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Country*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    name: "Country",
                    reduce: (x) => x.code,
                    options: _vm.formData.countryOptions,
                  },
                  model: {
                    value: _vm.form.countryCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "countryCode", $$v)
                    },
                    expression: "form.countryCode",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Country"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Latitude*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min_value:-90|max_value:90",
                      expression: "'required|min_value:-90|max_value:90'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { type: "number", name: "Latitude" },
                  model: {
                    value: _vm.form.latitude,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "latitude", _vm._n($$v))
                    },
                    expression: "form.latitude",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Latitude"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Longitude*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min_value:-180|max_value:180",
                      expression: "'required|min_value:-180|max_value:180'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { type: "number", name: "Longitude" },
                  model: {
                    value: _vm.form.longitude,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "longitude", _vm._n($$v))
                    },
                    expression: "form.longitude",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Longitude"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Is Active")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-switch", {
                  model: {
                    value: _vm.form.isActive,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isActive", $$v)
                    },
                    expression: "form.isActive",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("content-list", {
            attrs: { contentList: _vm.contentList, formData: _vm.formData },
            on: {
              onEdit: _vm.handleEditContent,
              onAdd: _vm.handleAddContent,
              onSorted: _vm.handleChangeSorted,
              onDelete: _vm.handleDeleteContent,
            },
          }),
          _c("image-list", {
            attrs: { imageList: _vm.imageList },
            on: {
              onAdd: _vm.handleAddImage,
              onDelete: _vm.handleDeleteImage,
              onSorted: _vm.handleChangeImageSorted,
            },
          }),
          _c("room-list", {
            attrs: {
              roomList: _vm.roomList,
              propertyImages: _vm.imageList,
              formData: _vm.formData,
            },
            on: {
              onEdit: _vm.handleEditRoom,
              onAdd: _vm.handleAddRoom,
              onDelete: _vm.handleDeleteRoom,
            },
          }),
          _c("linked-applications", {
            attrs: { appList: _vm.linkedApplications },
            on: { onAdd: _vm.handleAddApp, onDelete: _vm.handleDeleteApp },
          }),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "danger", type: "filled" },
                  on: { click: _vm.onCancel },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "ml-2",
                  attrs: { color: "primary", type: "filled" },
                  on: { click: _vm.onSave },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }