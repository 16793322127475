var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    { attrs: { width: "550", height: "460", zIndex: "99999" } },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(_vm._s(_vm.headingTitle)),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", {}, [_vm._v("Title")]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:100",
                    expression: "'required|max:100'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Title" },
                model: {
                  value: _vm.content.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.content, "title", $$v)
                  },
                  expression: "content.title",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Title"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full mt-4" },
            [
              _c("label", {}, [_vm._v("Content Type")]),
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  value: _vm.content.typeId,
                  name: "Content Type",
                  reduce: (x) => x.code,
                  options: _vm.formData.contentTypeOptions,
                },
                on: { input: _vm.setSelectedContentType },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Content Type"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full mt-4" },
            [
              _c("label", {}, [_vm._v("Language")]),
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: "Language",
                  reduce: (x) => x.code.toLowerCase(),
                  options: _vm.formData.languageOptions,
                },
                model: {
                  value: _vm.content.language,
                  callback: function ($$v) {
                    _vm.$set(_vm.content, "language", $$v)
                  },
                  expression: "content.language",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Language"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full mt-4" },
            [
              _c("label", {}, [_vm._v("Content Text")]),
              _c("quill-editor", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                staticStyle: { height: "160px" },
                attrs: { name: "Content Text", options: _vm.editorOption },
                model: {
                  value: _vm.content.contentText,
                  callback: function ($$v) {
                    _vm.$set(_vm.content, "contentText", $$v)
                  },
                  expression: "content.contentText",
                },
              }),
              _c("div", { staticClass: "mt-12" }, [
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Content Text"))),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-end",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "danger", type: "filled" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.saveContent },
            },
            [_vm._v(_vm._s(_vm.buttonText))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }