var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex items-start mt-4 mt-base mb-4" },
        [
          _c("h5", { staticClass: "mt-1 font-medium" }, [_vm._v("Images")]),
          _c(
            "vs-button",
            {
              staticClass: "ml-4",
              attrs: { color: "primary", type: "border", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$refs.imageInput.click()
                },
              },
            },
            [_vm._v("Add")]
          ),
          _c("input", {
            ref: "imageInput",
            attrs: {
              type: "file",
              hidden: "",
              id: "imageInput",
              accept: "image/*",
            },
            on: { change: _vm.onAddImage },
          }),
        ],
        1
      ),
      _c("ag-grid-vue", {
        ref: "agGridTable",
        staticClass: "ag-theme-material mb-3",
        attrs: {
          components: _vm.cellRendererComponents,
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          rowData: _vm.imageList,
          gridOptions: _vm.gridOptions,
          rowDragManaged: true,
          animateRows: true,
          domLayout: "autoHeight",
          rowSelection: "single",
          pagination: false,
          suppressPaginationPanel: true,
          context: _vm.context,
        },
        on: {
          "first-data-rendered": _vm.onFirstDataRendered,
          "grid-ready": _vm.onGridReady,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }